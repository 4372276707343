import React, { useState, useCallback, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import * as callInsightsActions from '../../store/callInsights/actions';
import { useDispatch, useSelector } from 'react-redux';
import { calculateUserCallInsights } from '../../selectors/call';
import { isAdmin as isAdminSelector } from '../../selectors/auth';
import { isOperator as isOperatorSelector } from '../../selectors/auth';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/CallInsights/tableColumns';
import {
  CountRowTablePlacement,
  ExportButtonPlacement,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';
import roles from '../../config/roles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const Insights = (props) => {
  const isAdmin = useSelector(isAdminSelector);
  const isOperator = useSelector(isOperatorSelector);
  const userId = useSelector((state) => state.Auth.admin.id);

  const loadingCalls = useSelector((state) => state.Call.loading);
  const loadingMonetizationItems = useSelector(
    (state) => state.MonetizationItem.loading,
  );
  const loading = loadingCalls || loadingMonetizationItems;

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const insights = useSelector(
    calculateUserCallInsights(
      [roles.TEAM_LEADER, roles.OPERATOR],
      isOperator ? userId : null,
    ),
  );

  const startDate = useSelector((state) => state.CallInsights.startDate);
  const endDate = useSelector((state) => state.CallInsights.endDate);
  const dataFilters = useSelector((state) => state.CallInsights.filters);
  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const maxDate = moment.utc();
  const minDate = moment.utc('04/07/2020', 'DD/MM/YYYY');

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: momentStartDate || moment.utc().startOf('day'),
    endDate: momentEndDate || moment.utc(),
    sector: dataFilters?.sector || '',
    campaignIds: dataFilters?.campaignIds || null,
  });

  const dispatch = useDispatch();

  const refresh = useCallback(({ startDate, endDate, sector, campaignIds }) => {
    const selectedStartDate = startDate.utc().startOf('day').toDate();
    const selectedEndDate = endDate.utc().endOf('day').toDate();
    dispatch(
      callInsightsActions.setFilters(selectedStartDate, selectedEndDate, {
        sector,
        campaignIds,
      }),
    );
    setSelectedFilters({ startDate, endDate, sector, campaignIds });
  });

  const { t } = useTranslation();

  const columns = useMemo(
    () => createTableColumns(t, isAdmin).filter((column) => !!column),
    [t, isAdmin],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={t('Call Insights')}
            onRefresh={() => refresh(selectedFilters)}
            loading={loadingCalls || loadingMonetizationItems}
          />
          <DateRangeFilter
            onUpdate={refresh}
            minDate={minDate}
            maxDate={maxDate}
            startDate={selectedFilters.startDate}
            endDate={selectedFilters.endDate}
            loading={loadingCalls || loadingMonetizationItems}
            showFilters
            initialFilters={selectedFilters}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end m-auto">
                    <CountRowTablePlacement />
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                    <ExportButtonPlacement />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={insights}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Insights;

import React from 'react';
import {
  ViewButton,
  ViewLeadButton,
} from '../Common/DataTable/tableActionButtons';
import {
  getFilter,
  getLabel,
  getSort,
  userName,
} from '../Common/DataTable/tableHelpers';
import { capitalize } from '../../helpers/sharedFunction';
import moment from 'moment';

const printPayload = (cell) =>
  cell
    ? Object.keys(cell)
        .sort()
        .map((key) => `${key}: ${cell[key]}`)
        .join(',')
    : '';

const createTableColumns = (
  openLeadModal,
  openViewModal,
  monetizationOrders,
  acquisitionOrders,
  users,
  showSensitiveData,
  t,
) => {
  const actions =
    openLeadModal || openViewModal
      ? {
          title: 'Action',
          name: 'action',
          width: 140,
          align: 'center',
          formatterComponent: ({ row }) => (
            <ul className="list-inline font-size-20 contact-links mb-0">
              {openViewModal && (
                <ViewButton row={row} onPress={openViewModal} />
              )}
              {openLeadModal && (
                <ViewLeadButton row={row} onPress={openLeadModal} />
              )}
            </ul>
          ),
          filter: 'disabled',
          sorting: 'disabled',
        }
      : {
          title: 'Action',
          name: 'action',
          hidden: true,
          filter: 'disabled',
          sorting: 'disabled',
        };

  return [
    {
      title: 'AcquisitionOrder',
      name: 'acquisitionOrderId',
      getCellValue: (row) =>
        getLabel(row.acquisitionOrderId, acquisitionOrders),
    },
    {
      title: 'MonetizationOrder',
      name: 'monetizationOrderId',
      getCellValue: (row) =>
        getLabel(row.monetizationOrderId, monetizationOrders),
    },
    {
      title: 'Status',
      name: 'status',
      width: 120,
      formatterComponent: ({ value }) =>
        value === 'closed' ? (
          <span className="badge badge-success font-size-12">{t(value)}</span>
        ) : (
          <span className="badge badge-danger font-size-12">{t(value)}</span>
        ),
      sorting: (a, b) => getSort(t(a), t(b)),
      filter: (value, filter) => getFilter(t(value), filter.value),
    },
    {
      title: 'Revenue',
      name: 'revenue',
      width: 80,
      formatterComponent: ({ value }) =>
        `€ ${showSensitiveData ? value : '**'}`,
      filter: showSensitiveData ? undefined : 'disabled',
      sorting: showSensitiveData ? undefined : 'disabled',
    },
    {
      title: 'Qualify Cost',
      name: 'qualifyCost',
      width: 100,
      formatterComponent: ({ value }) =>
        `€ ${showSensitiveData ? value : '**'}`,
      filter: showSensitiveData ? undefined : 'disabled',
      sorting: showSensitiveData ? undefined : 'disabled',
    },
    {
      title: 'Closure',
      width: 120,
      name: 'closure',
      getCellValue: (row) => (row.revenue > 0 ? 'positive' : 'negative'),
      formatterComponent: ({ value }) =>
        value === 'positive' ? (
          <span className="badge badge-success font-size-12">{t(value)}</span>
        ) : (
          <span className="badge badge-danger font-size-12">{t(value)}</span>
        ),
      sorting: 'disabled',
    },
    {
      title: 'Attempts',
      name: 'attempts',
      width: 80,
      hidden: true,
    },
    {
      title: 'Error',
      name: 'error',
      width: 150,
      hidden: true,
    },
    {
      title: 'Positive Outcome',
      name: 'positiveOutcome',
      width: 150,
      hidden: true,
    },
    {
      title: 'Negative Outcome',
      name: 'negativeOutcome',
      width: 150,
      formatterComponent: ({ value }) => (value ? t(capitalize(value)) : ''),
      sorting: (a, b) => getSort(a || '', b || ''),
      filter: (value, filter) => getFilter(value || '', filter.value),
      hidden: true,
    },
    {
      title: 'Lead ID',
      name: 'leadId',
      hidden: true,
    },
    {
      title: 'Operator',
      width: 150,
      name: 'userId',
      getCellValue: (row) => userName(row.userId, users),
      formatterComponent: ({ value }) => userName(value, users),
    },
    {
      title: 'Payload',
      width: 150,
      name: 'payload',
      formatterComponent: ({ value }) => printPayload(value),
      filter: (value, filter) => getFilter(printPayload(value), filter.value),
      sorting: 'disabled',
      hidden: true,
    },
    {
      title: 'Sent At',
      name: 'sentAt',
      width: 120,
      formatterComponent: ({ value }) =>
        value ? `${moment(value).format('DD/MM/YYYY HH:mm')}` : '',
      filter: (value, filter) =>
        getFilter(
          value ? `${moment(value).format('DD/MM/YYYY HH:mm')}` : '',
          filter.value,
        ),
      exportValue: ({ value }) =>
        value ? moment(value).format('DD/MM/YYYY HH:mm') : '',
      hidden: true,
    },
    {
      title: 'Created At',
      name: 'createdAt',
      width: 120,
      formatterComponent: ({ value }) => t('date_fromNow', { date: value }),
      filter: (value, filter) =>
        getFilter(t('date_fromNow', { date: value }), filter.value),
      exportValue: ({ value }) => moment(value).format('DD/MM/YYYY HH:mm:ss'),
    },
    actions,
  ];
};

export default createTableColumns;

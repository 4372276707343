import { takeLeading, select, put, all, call } from 'redux-saga/effects';
import types from './actionTypes';
import * as dailyInsightsActions from './actions';
import {
  getFirestore,
  query,
  collection,
  where,
  orderBy,
} from 'firebase/firestore';
import rsf from '../../../helpers/firebase';
import {
  toDateFirebase,
  arrayChunks,
  isNullish,
} from '../../../helpers/sharedFunction';

export const dailyInsightTransformer = (insight, data) => {
  const date = toDateFirebase(insight, data, 'date').toDate();
  const totalSupplierCosts = data.totalSupplierCosts || 0;
  const totalCosts =
    data.positiveQualifiedCosts + data.acquisitionCosts + totalSupplierCosts;
  const margin = data.revenues - totalCosts;

  return {
    id: insight.id,
    ...data,
    date,
    totalSupplierCosts,
    totalCosts,
    margin,
  };
};

function* fetchDailyInsightsSaga({ startDate, endDate, filters }) {
  try {
    const companyId = yield select((state) => state.Dashboard.companyId);
    let dailyInsightsSnaps = null;

    const db = getFirestore(rsf.app);
    const insightQuery = query(
      collection(db, 'dailyMonetizationOrderInsights'),
      where('date', '>=', startDate),
      where('date', '<=', endDate),
      where('companyId', '==', companyId),
      orderBy('date', 'desc'),
    );
    if (!filters || isNullish(filters)) {
      dailyInsightsSnaps = yield call(
        rsf.firestore.getCollection,
        insightQuery,
      );
      dailyInsightsSnaps = [dailyInsightsSnaps];
    } else {
      const monetizationOrders = arrayChunks(filters.monetizationOrderIds, 10);

      dailyInsightsSnaps = yield all(
        monetizationOrders.map((monetizationOrderId) =>
          call(
            rsf.firestore.getCollection,
            query.where('monetizationOrderId', 'in', monetizationOrderId),
          ),
        ),
      );
    }

    let dailyInsights = [];

    dailyInsightsSnaps.forEach((dailyInsightsSnap) => {
      dailyInsightsSnap.forEach((insight) => {
        const data = insight.data();
        dailyInsights.push(dailyInsightTransformer(insight, data));
      });
    });

    yield put(
      dailyInsightsActions.fetchDailyInsightsSuccess(
        dailyInsights,
        startDate,
        endDate,
        filters,
      ),
    );
  } catch (error) {
    yield put(dailyInsightsActions.fetchDailyInsightsFailure(error));
  }
}

function* dailyInsightsSaga() {
  yield all([
    takeLeading(
      types.DAILY_MONETIZATION_ORDER_INSIGHTS.REQUEST,
      fetchDailyInsightsSaga,
    ),
  ]);
}

export default dailyInsightsSaga;

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Row, Col, Card, CardBody, Spinner } from 'reactstrap';

import Modal from '../../components/Common/Modal/Modal';
import LeadHistory from '../../components/Leads/LeadHistory';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import Breadcrumbs from '../../components/Common/Breadcrumb';

import * as leadsActions from '../../store/leads/actions';
import * as campaignsActions from '../../store/campaigns/actions';
import * as leadFormsActions from '../../store/leadForms/actions';
import { useDispatch, useSelector } from 'react-redux';
import { userPublisherId } from '../../selectors/auth';
import { selectCampaignsByPublisher } from '../../selectors/campaign';

import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/Leads/Publisher/tableColumns';
import { CountRowTablePlacement } from '../../components/Common/DataTable/tableActionButtons';
import moment from 'moment';

const PublisherLeads = (props) => {
  const [selectedLead, setSelectedLead] = useState(null);
  const [historyModal, setHistoryModal] = useState(false);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const leads = useSelector((state) => state.Leads.leads);
  const supplierId = useSelector(userPublisherId);
  const campaigns = useSelector(selectCampaignsByPublisher(supplierId));
  const showSensitiveData = useSelector(
    (state) => state.Layout.showSensitiveData,
  );
  const loading = useSelector((state) => state.Leads.loading);

  const startDate = useSelector((state) => state.Leads.startDate);
  const endDate = useSelector((state) => state.Leads.endDate);
  const dataFilters = {
    supplierId,
    campaignIds: null,
  };
  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const maxDate = moment.utc();
  const minDate = moment.utc('04/07/2020', 'DD/MM/YYYY');

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: momentStartDate || moment.utc().startOf('month'),
    endDate: momentEndDate || moment.utc(),
    supplierId: dataFilters?.supplierId || supplierId || '',
    campaignIds: dataFilters.campaignIds,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(leadFormsActions.syncLeadForms());
    dispatch(campaignsActions.syncCampaigns());
  }, []);

  const refresh = useCallback(
    ({ startDate, endDate, supplierId, campaignIds }) => {
      const selectedStartDate = startDate.utc().startOf('day').toDate();
      const selectedEndDate = endDate.utc().endOf('day').toDate();
      dispatch(
        leadsActions.fetchLeads(selectedStartDate, selectedEndDate, {
          supplierId,
          campaignIds:
            campaignIds || campaigns.slice(0, 10).map(({ id }) => id),
        }),
      );
      setSelectedFilters({ startDate, endDate, supplierId, campaignIds });
    },
  );

  const openHistoryModal = (lead) => {
    setSelectedLead(lead);
    setHistoryModal(true);
  };

  const columns = useMemo(
    () => createTableColumns(openHistoryModal, campaigns, showSensitiveData),
    [campaigns, showSensitiveData],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );

  return (
    <React.Fragment>
      <Breadcrumbs
        title="Leads"
        breadcrumbItem="List"
        onRefresh={() => refresh(selectedFilters)}
        loading={loading}
      />
      <DateRangeFilter
        onUpdate={refresh}
        minDate={minDate}
        maxDate={maxDate}
        startDate={selectedFilters.startDate}
        endDate={selectedFilters.endDate}
        loading={loading}
        showPublisherFilters
        initialFilters={selectedFilters}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <Row className="align-items-center justify-content-end m-auto">
                <CountRowTablePlacement />
                <Spinner
                  color="secondary"
                  animation="border"
                  hidden={!loading}
                />
              </Row>
              <DataTableNext
                loading={loading}
                rows={leads}
                columns={columns}
                filters={filters}
                onFiltersChange={setFilters}
                sorting={sorting}
                onSortingChange={setSorting}
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                hiddenColumns={hiddenColumns}
                onHiddenColumnsChange={setHiddenColumns}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        size="xl"
        title="Lead"
        isOpen={historyModal}
        toggle={() => setHistoryModal(!historyModal)}
        scrollable={false}>
        <LeadHistory lead={selectedLead} />
      </Modal>
    </React.Fragment>
  );
};

export default PublisherLeads;

import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Collapse,
  InputGroupAddon,
} from 'reactstrap';
import { Form } from '@availity/form';
import { Select, Input, Switch } from '../../lib/form-field';
import '@availity/yup';
import formValidation from './formValidation';

import * as acquisitionOrdersActions from '../../store/acquisitionOrders/actions';
import * as messageTemplatesActions from '../../store/messageTemplates/actions';
import * as monetizationOrdersActions from '../../store/monetizationOrders/actions';
import * as leadFormsActions from '../../store/leadForms/actions';
import { useSelector, useDispatch } from 'react-redux';

import QueryBuilder from '../../components/Common/QueryBuilder';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { SubmitButton } from '../Common/Modal/modalActionButtons';
import { components } from 'react-select';
import { capitalize } from '../../helpers/sharedFunction';
import sectors from '../../config/sectors';
import customerWebServices from '../../config/customerWebServices';
import toastr from 'toastr';

const MAX_SELECTED_MONETIZATION_ORDERS = 30;
const { Option } = components;

const MessageTemplateSelectOption = (props) => (
  <Option {...props}>
    {props.data.type
      ? `${capitalize(props.data.type)}${
          props.data.linkType ? ' - ' + capitalize(props.data.linkType) : ''
        } - ${props.data.label}`
      : props.data.label}
  </Option>
);

const FormAcquisitionOrder = (props) => {
  const editingOrder = props.acquisitionOrder || {};

  const emptyEditor = EditorState.createEmpty();

  const monetizationOrders = useSelector(
    (state) => state.MonetizationOrder.monetizationOrders,
  );
  const leadForms = useSelector((state) => state.LeadForm.leadForms);
  const leadFields = useSelector((state) => state.LeadField.leadFields);
  const [active, setActive] = useState(editingOrder.active || false);
  const [qualified, setQualified] = useState(editingOrder.qualified || 'no');

  const messageTemplates = props.messageTemplates.map(
    ({ id, name, templateType, linkType }) => ({
      value: id,
      label: name,
      type: templateType,
      linkType: linkType,
    }),
  );

  const initialQuery = editingOrder.query || null;
  const initialSqlQuery = editingOrder.sqlQuery || null;
  const initialJsonLogicQuery = editingOrder.jsonLogicQuery || null;
  const initialNote = editingOrder.note
    ? EditorState.createWithContent(
        convertFromRaw(JSON.parse(editingOrder.note)),
      )
    : emptyEditor;
  const initialSuggestion = editingOrder.suggestion
    ? EditorState.createWithContent(
        convertFromRaw(JSON.parse(editingOrder.suggestion)),
      )
    : emptyEditor;

  const [selectedQuery, setSelectedQuery] = useState(initialQuery);
  const [selectedSqlQuery, setSelectedSqlQuery] = useState(initialSqlQuery);
  const [selectedJsonLogicQuery, setSelectedJsonLogicQuery] = useState(
    initialJsonLogicQuery,
  );
  const [selectedNote, setSelectedNote] = useState(initialNote);
  const [selectedSuggestion, setSelectedSuggestion] =
    useState(initialSuggestion);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(messageTemplatesActions.syncMessageTemplates());
    dispatch(monetizationOrdersActions.syncMonetizationOrders());
    dispatch(leadFormsActions.syncLeadForms());
  }, []);

  const handleSubmit = (values) => {
    if (qualified === 'yes' && !selectedQuery)
      return toastr.error('Check the fields!', 'Error');
    const { messageTemplateId, negativeMessageTemplateId } = values;
    if (
      messageTemplateId !== 'none' &&
      messageTemplateId === negativeMessageTemplateId
    )
      return toastr.error(
        'Select different message template, please!',
        'Error',
      );

    const negativeReasonsArray = !values.negativeReasons
      ? []
      : Array.isArray(values.negativeReasons)
      ? values.negativeReasons
      : values.negativeReasons.split(',');

    const jsonLogicQuery = selectedJsonLogicQuery?.logic
      ? selectedJsonLogicQuery
      : '';

    const acquisitionOrder = {
      ...values,
      query: selectedQuery || '',
      sqlQuery: selectedSqlQuery || '',
      jsonLogicQuery,
      negativeReasons: negativeReasonsArray,
      note:
        selectedNote === emptyEditor
          ? null
          : JSON.stringify(convertToRaw(selectedNote.getCurrentContent())),
      suggestion:
        selectedSuggestion === emptyEditor
          ? null
          : JSON.stringify(
              convertToRaw(selectedSuggestion.getCurrentContent()),
            ),
      active,
    };

    dispatch(
      props.create
        ? acquisitionOrdersActions.createAcquisitionOrder({
            manageOnDante: 'no',
            ...acquisitionOrder,
          })
        : acquisitionOrdersActions.updateAcquisitionOrder(
            {
              id: editingOrder.id,
              ...acquisitionOrder,
            },
            editingOrder.campaignIds,
          ),
    );
    props.closeModal();
  };

  const handleQueryTree = (jsonTree, sqlQuery, jsonLogicQuery) => {
    setSelectedQuery(jsonTree);
    setSelectedSqlQuery(sqlQuery);
    setSelectedJsonLogicQuery(jsonLogicQuery);
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{
            qualified: 'no',
            ...editingOrder,
            negativeReasons: Array.isArray(editingOrder.negativeReasons)
              ? editingOrder.negativeReasons.join(',')
              : editingOrder.negativeReasons,
          }}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid, values }) => (
            <>
              <Row>
                <Col md="3">
                  <Input id="name" name="name" />
                </Col>
                <Col md="3">
                  <Select
                    name="customerId"
                    id="customerId"
                    label="Customer"
                    isMulti={false}
                    options={
                      Array.isArray(props.customers)
                        ? props.customers.map(({ id, name }) => ({
                            label: name,
                            value: id,
                          }))
                        : []
                    }
                  />
                </Col>
                <Col md="6">
                  <Select
                    name="campaignIds"
                    id="campaignIds"
                    label="Campaigns"
                    isMulti
                    options={props.campaigns}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <Select
                    name="qualified"
                    id="qualified"
                    label="Qualified?"
                    onChange={setQualified}
                    value={qualified}
                    options={[
                      {
                        label: 'Yes',
                        value: 'yes',
                      },
                      {
                        label: 'No',
                        value: 'no',
                      },
                    ]}
                  />
                </Col>
                <Col md="3">
                  <Select
                    name="messageTemplateId"
                    id="messageTemplateId"
                    label="Message on entry"
                    isMulti={false}
                    options={[
                      {
                        value: 'none',
                        label: 'None',
                        type: null,
                        linkType: null,
                      },
                      ...messageTemplates,
                    ]}
                    components={{ Option: MessageTemplateSelectOption }}
                  />
                </Col>
                <Col md="2">
                  <Select
                    name="leadFormId"
                    id="leadFormId"
                    label="Lead Form"
                    options={[
                      { value: 'none', label: 'None' },
                      ...leadForms.map(({ id, name }) => ({
                        label: name,
                        value: id,
                      })),
                    ]}
                  />
                </Col>
                <Col md="2">
                  <Select
                    name="sector"
                    id="sector"
                    label="Sector"
                    options={sectors}
                    isDisabled={!props.create}
                  />
                </Col>
                <Col md="1">
                  <Input
                    id="priority"
                    name="priority"
                    type="number"
                    step="1"
                    min="1"
                    max="10"
                  />
                  <p className="font-size-12 text-muted">
                    1 = highest priority
                  </p>
                </Col>
                <Col md="2">
                  <Switch
                    name="active"
                    label="Active?"
                    defaultChecked={active}
                    onClick={() => setActive(!active)}
                  />
                </Col>
              </Row>
              <Collapse isOpen={qualified === 'yes'}>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <QueryBuilder
                        label="Conditional logic for call-center sending"
                        debug={false}
                        fields={leadFields}
                        onChange={handleQueryTree}
                        initialQuery={initialQuery}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <Input
                      id="costNegativeClosure"
                      name="costNegativeClosure"
                      label="Cost per negative closure"
                      min={0}
                      type="number"
                      step="0.01">
                      <InputGroupAddon addonType="append">€</InputGroupAddon>
                    </Input>
                  </Col>
                  <Col md="2">
                    <Input
                      id="maxCallAttempts"
                      name="maxCallAttempts"
                      label="Max call attempts"
                      min={2}
                      type="number"
                      step="1"
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      id="phoneExchangeRoute"
                      name="phoneExchangeRoute"
                      label="Phone exchange route"
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      id="phoneExchangeQueue"
                      name="phoneExchangeQueue"
                      label="Phone exchange queue"
                    />
                  </Col>
                  <Col md="3">
                    <Input
                      id="sendMessageAfterRecall"
                      name="sendMessageAfterRecall"
                      label="Send Message after recall n."
                      defaultValue={0}
                      min={0}
                      max={parseInt(values.maxCallAttempts) - 1}
                      type="number"
                      step="1"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Input
                      id="negativeReasons"
                      name="negativeReasons"
                      type="textarea"
                      label="Custom Negative Reasons"
                    />
                    <p className="font-size-12 text-muted">
                      Insert comma separated strings
                    </p>
                  </Col>
                  <Col md="2">
                    <Input
                      id="callStopwatch"
                      name="callStopwatch"
                      label="Timer (in minutes)"
                      defaultValue={0}
                      min={0}
                      max={10}
                      type="number"
                      step="0.1"
                    />
                  </Col>
                  <Col md="3">
                    <Collapse
                      isOpen={parseInt(values.sendMessageAfterRecall) > 0}>
                      <Select
                        name="recallMessageTemplateId"
                        id="recallMessageTemplateId"
                        label="Recall Message Template"
                        options={messageTemplates}
                        components={{ Option: MessageTemplateSelectOption }}
                      />
                    </Collapse>
                  </Col>
                </Row>
              </Collapse>
              <Row>
                <Col md="12">
                  <Select
                    name="monetizationOrdersAllowed"
                    id="monetizationOrdersAllowed"
                    label="Monetization Orders allowed"
                    isMulti
                    maxLength={MAX_SELECTED_MONETIZATION_ORDERS}
                    isSearchable
                    options={monetizationOrders.map((mo) => ({
                      value: mo.id,
                      label: mo.name,
                    }))}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <Collapse isOpen={qualified === 'yes'}>
                    <Select
                      name="negativeWebService"
                      id="negativeWebService"
                      label="Web Service on negative"
                      options={[
                        { value: 'none', label: 'None' },
                        ...customerWebServices,
                      ]}
                    />
                    <p className="font-size-12 text-muted">
                      All negative lead statuses including 'expired' are
                      monitored.
                    </p>
                  </Collapse>
                </Col>
                <Col md="3">
                  <Select
                    name="negativeMessageTemplateId"
                    id="negativeMessageTemplateId"
                    label="Message on negative"
                    isMulti={false}
                    options={[
                      {
                        value: 'none',
                        label: 'None',
                        type: null,
                        linkType: null,
                      },
                      ...messageTemplates,
                    ]}
                    components={{ Option: MessageTemplateSelectOption }}
                  />
                </Col>
                <Col md="2">
                  <Collapse
                    isOpen={
                      values.negativeMessageTemplateId &&
                      values.negativeMessageTemplateId !== 'none'
                    }>
                    <Input
                      id="negativeMessageSendDelay"
                      name="negativeMessageSendDelay"
                      label="Negative Send Delay"
                      min={1} //min 1 delay day because the person must be created before sending a message
                      type="number"
                      step="1"
                    />
                    <p className="font-size-12 text-muted">Value in days</p>
                  </Collapse>
                </Col>
                <Col md="3">
                  <Select
                    name="expiredMessageTemplateId"
                    id="expiredMessageTemplateId"
                    label="Message on expired"
                    isMulti={false}
                    options={[
                      {
                        value: 'none',
                        label: 'None',
                        type: null,
                        linkType: null,
                      },
                      ...messageTemplates,
                    ]}
                    components={{ Option: MessageTemplateSelectOption }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label htmlFor="note">Note</Label>
                    <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      placeholder="Place Your Content Here..."
                      editorState={selectedNote}
                      onEditorStateChange={(editorState) =>
                        setSelectedNote(editorState)
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label htmlFor="note">Suggestion</Label>
                    <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      placeholder="Place Your Content Here..."
                      editorState={selectedSuggestion}
                      onEditorStateChange={(editorState) =>
                        setSelectedSuggestion(editorState)
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormAcquisitionOrder;

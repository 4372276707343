import React, { useState, useCallback } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Collapse,
  InputGroupAddon,
} from 'reactstrap';
import * as monetizationOrdersActions from '../../store/monetizationOrders/actions';
import { selectMessageTemplateByMonetizationOrder } from '../../selectors/messageTemplate';
import { selectSupplierByType } from '../../selectors/supplier';
import { useDispatch, useSelector } from 'react-redux';

import QueryBuilder from '../Common/QueryBuilder';
import { Form } from '@availity/form';
import { Input, Select, Date, Switch } from '../../lib/form-field';
import '@availity/yup';
import formValidation from './formValidation';
import { SubmitButton } from '../Common/Modal/modalActionButtons';
import { components } from 'react-select';
import { capitalize } from '../../helpers/sharedFunction';

import customerWebServices from '../../config/customerWebServices';
import sendLeadTypes from '../../config/sendLeadTypes';
import supplierTypes from '../../config/supplierTypes';
import {
  COMPASS_FA3_LINK_PREFIX,
  COMPASS_REDIRECT_LINK,
} from '../../config/sendLeadTypes';
import { useTranslation } from 'react-i18next';

const { Option } = components;

const MessageTemplateSelectOption = (props) => (
  <Option {...props}>
    {props.data.type
      ? `${capitalize(props.data.type)}${
          props.data.linkType ? ' - ' + capitalize(props.data.linkType) : ''
        } - ${props.data.label}`
      : props.data.label}
  </Option>
);

const messageTemplateForSelect = (messageTemplates) =>
  messageTemplates.map((message) => ({
    value: message.id,
    label: message.name,
    type: message.templateType,
    linkType: message.linkType,
  }));

const FormMonetizationOrder = (props) => {
  const editingOrder = props.monetizationOrder || {};

  const sales = useSelector(selectSupplierByType(supplierTypes.SALE));
  const leadFields = useSelector((state) => state.LeadField.leadFields);
  const spreadsheetLeadFields = leadFields.filter(
    ({ typeField }) => typeField !== 'async-select',
  );

  const [netRebate, setNetRebate] = useState(editingOrder.netRebate || false);

  const trackingMessageTemplates = useSelector(
    selectMessageTemplateByMonetizationOrder(editingOrder.id),
  );

  const initialQuery = editingOrder.query || null;
  const initialSqlQuery = editingOrder.sqlQuery || null;
  const initialJsonLogicQuery = editingOrder.jsonLogicQuery || null;

  const [selectedQuery, setSelectedQuery] = useState(initialQuery);
  const [selectedSqlQuery, setSelectedSqlQuery] = useState(initialSqlQuery);
  const [selectedJsonLogicQuery, setSelectedJsonLogicQuery] = useState(
    initialJsonLogicQuery,
  );

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const { reasons, supplierId, sendLeadType, googleSpreadsheetId } = values;

    const reasonsArray = !reasons
      ? []
      : Array.isArray(reasons)
      ? reasons
      : reasons.split(',');

    const jsonLogicQuery = selectedJsonLogicQuery?.logic
      ? selectedJsonLogicQuery
      : '';

    const monetizationOrder = {
      ...values,
      query: selectedQuery || '',
      sqlQuery: selectedSqlQuery || '',
      jsonLogicQuery,
      reasons: reasonsArray,
      ...(supplierId && { netRebate }),
      ...(sendLeadType === 'googleSpreadsheets' &&
        !googleSpreadsheetId && { googleSpreadsheetId: 'new' }),
    };

    dispatch(
      props.create
        ? monetizationOrdersActions.createMonetizationOrder(monetizationOrder)
        : monetizationOrdersActions.updateMonetizationOrder({
            id: editingOrder.id,
            ...monetizationOrder,
          }),
    );
    props.closeModal();
  };

  const handleQueryTree = (jsonTree, sqlQuery, jsonLogicQuery) => {
    setSelectedQuery(jsonTree);
    setSelectedSqlQuery(sqlQuery);
    setSelectedJsonLogicQuery(jsonLogicQuery);
  };

  const handleGenerateCompassLink = useCallback((values, setFieldValue) => {
    const { landingPageCustomer = '' } = values;
    if (!landingPageCustomer.includes(COMPASS_FA3_LINK_PREFIX)) return;

    const compassLink = `${COMPASS_REDIRECT_LINK}${encodeURIComponent(
      landingPageCustomer,
    )}`;
    setFieldValue('landingPageCustomer', compassLink);
  }, []);

  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{
            ...editingOrder,
            reasons: Array.isArray(editingOrder.reasons)
              ? editingOrder.reasons.join(',')
              : editingOrder.reasons,
          }}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid, values, setFieldValue }) => (
            <>
              <Row>
                <Col md="3">
                  <Input id="name" name="name" />
                </Col>
                <Col md="3">
                  <Select
                    name="customerId"
                    id="customerId"
                    label="Customer"
                    isMulti={false}
                    options={
                      Array.isArray(props.customers)
                        ? props.customers.map(({ id, name }) => ({
                            label: name,
                            value: id,
                          }))
                        : []
                    }
                  />
                </Col>
                <Col md="2">
                  <Date
                    id="startDate"
                    name="startDate"
                    label="Start Date"
                    format="DD/MM/YYYY"
                  />
                </Col>
                <Col md="2">
                  <Date
                    id="endDate"
                    name="endDate"
                    format="DD/MM/YYYY"
                    label="End Date"
                  />
                </Col>
                <Col md="1">
                  <Input
                    id="priority"
                    name="priority"
                    type="number"
                    step="0.1"
                  />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <QueryBuilder
                      label="Logical condition for selecting leads to send"
                      fields={leadFields}
                      onChange={handleQueryTree}
                      initialQuery={initialQuery}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <Input
                    id="leads"
                    name="leads"
                    label="Goal Leads"
                    min={0}
                    type="number"
                    step="1"
                  />
                </Col>
                <Col md="2">
                  <Input
                    id="payout"
                    name="payout"
                    addonType="prepend"
                    min={0}
                    type="number"
                    step="0.01">
                    <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                  </Input>
                </Col>
                <Col md="2">
                  <Input
                    id="percentageRebate"
                    name="percentageRebate"
                    addonType="append"
                    label="Rebate"
                    min={0}
                    type="number"
                    step="0.01">
                    <InputGroupAddon addonType="append">%</InputGroupAddon>
                  </Input>
                </Col>
                <Col md="3">
                  <Select
                    name="messageTemplateId"
                    id="messageTemplateId"
                    label="Message on success"
                    isMulti={false}
                    options={[
                      { value: 'none', label: 'None', type: null },
                      ...messageTemplateForSelect(props.messageTemplates),
                    ]}
                    components={{ Option: MessageTemplateSelectOption }}
                  />
                </Col>
                <Col md="2">
                  <Collapse
                    isOpen={
                      values.messageTemplateId &&
                      values.messageTemplateId !== 'none'
                    }>
                    <Input
                      id="messageSendDelay"
                      name="messageSendDelay"
                      label="Send Delay"
                      min={0}
                      type="number"
                      step="1"
                    />
                    <p className="font-size-12 text-muted">
                      Value in days, 0 = now
                    </p>
                  </Collapse>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <Select
                    name="sendLeadType"
                    id="sendLeadType"
                    label="How Send Leads?"
                    options={sendLeadTypes}
                  />
                </Col>
                <Col md="6">
                  <Collapse
                    isOpen={
                      values.sendLeadType === 'dataEntry' ||
                      values.sendLeadType === 'trackingPixel'
                    }>
                    <Input
                      id="landingPageCustomer"
                      name="landingPageCustomer"
                      label="Landing Page Customer"
                      type="textarea"
                    />
                  </Collapse>
                  <Collapse
                    isOpen={values.sendLeadType === 'googleSpreadsheets'}>
                    <Select
                      name="spreadsheetLeadFieldIds"
                      id="spreadsheetLeadFieldIds"
                      label="Fields to export"
                      isMulti
                      isDisabled={!!editingOrder?.googleSpreadsheetId}
                      isSearchable
                      options={spreadsheetLeadFields.map(({ id, name }) => ({
                        label: t(name),
                        value: id,
                      }))}
                    />
                  </Collapse>
                </Col>
                <Col md="3">
                  <Collapse isOpen={values.sendLeadType === 'trackingPixel'}>
                    <Select
                      name="trackingMessageTemplateId"
                      id="trackingMessageTemplateId"
                      label="Message with Tracking Link"
                      isMulti={false}
                      options={messageTemplateForSelect(
                        trackingMessageTemplates,
                      )}
                      components={{ Option: MessageTemplateSelectOption }}
                    />
                  </Collapse>
                  <Collapse
                    isOpen={
                      values.sendLeadType === 'dataEntry' &&
                      values.landingPageCustomer &&
                      values.landingPageCustomer.includes(
                        COMPASS_FA3_LINK_PREFIX,
                      )
                    }>
                    <button
                      type="button"
                      className="btn btn-dark waves-effect waves-light"
                      onClick={() =>
                        handleGenerateCompassLink(values, setFieldValue)
                      }>
                      <i className="bx bx-link align-middle mr-2"></i>
                      {t('Get Compass FA3 link')}
                    </button>
                  </Collapse>
                </Col>
              </Row>
              <Collapse isOpen={values.sendLeadType === 'webService'}>
                <Row>
                  <Col md="3">
                    <Select
                      name="webService"
                      id="webService"
                      label="Web Service"
                      options={customerWebServices}
                    />
                  </Col>
                </Row>
              </Collapse>
              <Row>
                <Col md="3">
                  <Input
                    id="costPositiveClosure"
                    name="costPositiveClosure"
                    label="Cost per positive closure"
                    addonType="prepend"
                    min={0}
                    type="number"
                    step="0.01">
                    <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                  </Input>
                </Col>
                <Col md="3">
                  <Input
                    id="validLeads"
                    name="validLeads"
                    label="Total Valid Leads"
                    min={0}
                    type="number"
                    step="1"
                  />
                </Col>
                <Col md="6">
                  <Input id="reasons" name="reasons" type="textarea" />
                  <p className="font-size-12 text-muted">
                    Insert comma separated strings
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <Select
                    name="supplierId"
                    id="supplierId"
                    label="Sale"
                    options={
                      Array.isArray(sales)
                        ? sales.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))
                        : []
                    }
                  />
                </Col>
                <Col md="3">
                  <Collapse isOpen={!!values.supplierId}>
                    <Input
                      id="percentageRevenueShare"
                      name="percentageRevenueShare"
                      label="Percentage Revenue Share"
                      addonType="append"
                      min={0}
                      type="number"
                      step="0.1">
                      <InputGroupAddon addonType="append">%</InputGroupAddon>
                    </Input>
                  </Collapse>
                </Col>
                <Col md="3">
                  <Collapse isOpen={!!values.supplierId}>
                    <Input
                      id="percentageMarginShare"
                      name="percentageMarginShare"
                      label="Percentage Margin Share"
                      min={0}
                      type="number"
                      step="0.1">
                      <InputGroupAddon addonType="append">%</InputGroupAddon>
                    </Input>
                  </Collapse>
                </Col>
                <Col md="3">
                  <Collapse isOpen={!!values.supplierId}>
                    <Switch
                      name="netRebate"
                      label="Active?"
                      defaultChecked={netRebate}
                      onClick={() => setNetRebate(!netRebate)}
                    />
                  </Collapse>
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormMonetizationOrder;
